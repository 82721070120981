<template>
  <div>
    <p v-show="!initialVideoData && video.video !== '' && !(video.video instanceof Object)" class="md-error">
      {{ $t('validation.required.video') }}
    </p>

    <input
      style="display:none;"
      :ref="'input-' + index"
      type="file"
      :name="'video-' + index"
      accept="video/*"
      @change="setBlockVideo($event)"
    />

    <span v-if="video.video === '' && !sizeExceeded" class="video-size-hint">
      <v-icon>mdi-information-outline</v-icon>
      {{ $t('components.blocks-editor.blocks.video.max-size') }}: {{ sizeLimitMb }}MB
    </span>

    <span v-if="sizeExceeded" class="video-size-error">
      <v-icon>mdi-alert-outline</v-icon>
      {{ $t('components.blocks-editor.blocks.video.size-exceeded') }} ({{ sizeLimitMb }}MB)
    </span>

    <div v-if="isUploading">
      <v-progress-linear slot="progress" color="primary" indeterminate/>
    </div>

    <div v-if="video.id && !isUploading && !video.video">
      <div class="video-wrapper pb-4">
        <video :src="initialVideoData" controls></video>
      </div>
    </div>

    <div v-if="video.video && !isUploading && video.video instanceof Object">
      <div class="video-wrapper pb-4">
        <video :src="tempVideoSrc(video.video)" controls></video>
      </div>
    </div>

    <div v-show="true" class="sp-float-right">
      <v-btn
        elevation="0"
        color="primary"
        outlined
        class="icon-left"
        @click="showBlockVideoChooser()"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('components.blocks-editor.blocks.video.upload') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { API } from '@/auth'

export default {
  name: "ContentEditorBlockVideo",
  props: {
    value: {
      type: Object,
      default: () => {
        return  {}
      },
    },
    index: {},
    contentType: {
        default: 'news'
    }
  },
  data() {
    return {
      block: this.value,
      sizeLimitMb: 150,
      initialVideoData: null,
      sizeExceeded: false,
      isUploading: false,
    }
  },
  async created() {
    if (this.video.id && !this.initialVideoData) {
      this.initialVideoData = API + '/' + this.contentType + '/image/' + this.video.id
    }
  },
  computed: {
    video: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    videoFileInput() {
      return this.$refs['input-' + this.index]
    }
  },
  methods: {
    setBlockVideo(e) {
      const videoFile = e.target.files[0]
      if (videoFile.type.indexOf('video/') === -1) {
        alert('Bitte eine Videodatei auswählen')
        return
      }
      if (typeof FileReader === 'function') {
        this.isUploading = true
        const reader = new FileReader()
        reader.onload = () => {
          this.isUploading = false
          let sizeExceeded = videoFile.size > this.sizeLimitMb * 1024 * 1024
          if (sizeExceeded) {
            return
          }
          this.video.new_content = videoFile.name
          this.video.video = videoFile
          this.videoFileInput.value = null
        }
        reader.readAsDataURL(videoFile)
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    tempVideoSrc(video) {
      if (this.editorFocused) {
        return
      }
      return URL.createObjectURL(video)
    },
    showBlockVideoChooser() {
      this.videoFileInput.click()
    }
  }
}
</script>

<template>
  <div>
    <BasicCreateEditContainer
        :title="mode == 'create' ? t('create.title') : t('edit.title')"
        ref="editor"
        object-type="JobCategory"
        :object-name="data.name"
        :data-loaded="dataLoaded"
        @cancel="close"
        :save-action="saveItem"
        @saved="onSaved"
        :delete-action="deleteItem"
        @deleted="onDeleted"
        :allow-delete="mode == 'edit'"
        :has-changes="hasChanges"
    >
      <template v-slot:content>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>

            <v-text-field
                :label="t('details.name')"
                v-model="data.name"
                counter="40"
                data-cy="name"
                filled
                :rules="[
                  () => !!data.name || t('details.error-name-required'),
                  () => (data.name && data.name.length <= 40) || t('details.error-max-name')
                ]"
            ></v-text-field>

          </v-card-text>
        </v-card>
      </template>
    </BasicCreateEditContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'

export default {
  name: 'JobsCategoriesCreateEdit',
  components: {
    BasicCreateEditContainer,
  },
  data() {
    return {
      data: {
        id: null,
        name: ''
      },
      mode: null,
      dataLoaded: false,
      dataOriginal: null
    }
  },
  created() {
    this.data.id = this.$route.params.id
    if (this.data.id) {
      this.getItem()
      this.mode = 'edit'
    } else {
      this.mode = 'create'
      this.dataOriginal = JSON.parse(JSON.stringify(this.data))
      this.dataLoaded = true
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.categories.' + key)
    },
    getItem() {
      HTTP.get('jobs/categories/' + this.data.id).then(function (response) {
        this.data = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.data));
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
          this.dataLoaded = false
        }
      }.bind(this))
    },
    saveItem() {
      if (this.mode == 'create') {
        return HTTP.post('jobs/categories', this.data)
      } else {
        return HTTP.patch('jobs/categories/' + this.data.id, this.data)
      }
    },
    async onSaved() {
      this.dataOriginal = null
      this.close()
    },
    deleteItem() {
      return HTTP.delete('jobs/categories/' + this.data.id)
    },
    async onDeleted() {
      this.dataOriginal = null
      this.close()
    },
    close() {
      this.$router.push({ name: 'JobsCategoriesIndex' })
    }
  }
}
</script>

<template>
  <div>
    <BasicCreateEditContainer
      :title="getToolbarTitle()"
      ref="editor"
      object-type="News"
      :object-name="jobsData.title"
      :data-loaded="dataLoaded"
      @cancel="close"
      :allow-delete="false"
      :has-changes="hasChanges"
    >
      <template v-slot:content>

        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('details.title') + '*'"
              counter="120"
              v-model="jobsData.title"
              data-cy="title"
              filled
              :disabled="isArchived"
              :rules="[
                () => !!jobsData.title || $t('validation.required.title'),
                () => (jobsData.title && jobsData.title.length <= 120) || $t('validation.length.title')
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('details.description') + '*'"
              counter="120"
              v-model="jobsData.preview_text"
              data-cy="previewText"
              filled
              :disabled="isArchived"
              :rules="[
                () => !!jobsData.preview_text || $t('validation.required.description'),
                () => (jobsData.preview_text && jobsData.preview_text.length <= 120) || $t('validation.length.description')
              ]"
            ></v-text-field>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.blocks-editor.label') + '*' }}</v-card-title>
          <v-card-text>
            <content-editor content-type="jobs" v-model="jobsData.blocks"/>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="hasNewBlocksContent"
              color="primary"
              outlined
              class="icon-left phone-preview"
              @click="showPhonePreview"
            >
              <v-icon>mdi-cellphone-check</v-icon>
              {{ $t('components.blocks-editor.preview') }}
            </v-btn>
          </v-card-actions>
        </v-card>

      </template>
        
      <template v-slot:meta>

        <v-card elevation="0">
          <v-card-title>{{ $t('components.category-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <v-select
              filled
              :title="$t('components.category-select.select') + '*'"
              :label="$t('components.category-select.select') + '*'"
              v-model="jobsData.category"
              data-cy="categories"
              :items="categories"
              item-text="name"
              item-value="id"
              :disabled="isArchived"
              :rules="[
                () => !!jobsData.category || $t('validation.required.category')
              ]"
            ></v-select>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-push-settings.label') }}</v-card-title>
          <v-card-text v-if="mode === 'create'">
            <v-radio-group v-model="pushStatus" data-cy="pushStatus">
              <v-radio
                  key="manual"
                  :label="$t('components.post-push-settings.status.manual')"
                  value="manual"
              ></v-radio>

              <v-radio
                  key="automatic"
                  :label="$t('components.post-push-settings.status.auto')"
                  value="automatic"
              ></v-radio>
            </v-radio-group>
          </v-card-text>

          <v-card-text v-else>
            <div v-if="jobsData.pending_cronjob" class="pending-push-notification">
              <span>
                <v-icon>mdi-information-outline</v-icon>
                {{ $t('components.post-push-settings.push-pending') }}:
                <br>
                {{ initialjobsData.published_at | momentDate }} {{ initialjobsData.published_at | momentTime }}
              </span>

              <v-btn
                color="warning"
                elevation="0"
                class="icon-left push-abort"
                @click="pushAbort()"
              >
                <v-icon>mdi-cancel</v-icon>
                {{ $t('components.post-push-settings.cancel-push') }}
              </v-btn>
            </div>

            <div
              v-if="initialjobsData.published_at && (isPublished(initialjobsData.published_at) || isArchived)"
              class="push-notification"
            >
              <v-btn
                color="primary"
                elevation="0"
                class="icon-left push-abort"
                :disabled="!isPublished(jobsData.published_at)"
                @click="push()"
              >
                <v-icon>mdi-bell</v-icon>
                {{ $t('components.post-push-settings.do-push') }}
              </v-btn>

              <div v-if="jobsData.pushed_at" class="push-date">
                <span>
                  {{ $t('components.post-push-settings.last-push-at') }}:
                  <br>
                  {{ jobsData.pushed_at | momentDate }} {{ jobsData.pushed_at | momentTime }}
                </span>
              </div>
            </div>

            <div v-if="!jobsData.published_at || (!isPublished(initialjobsData.published_at) && !jobsData.pending_cronjob && status === 'published')">
              <v-radio-group v-model="pushStatus">
                <v-radio
                    key="manual"
                    :label="$t('components.post-push-settings.status.manual')"
                    value="manual"
                ></v-radio>

                <v-radio
                    key="automatic"
                    :label="$t('components.post-push-settings.status.auto')"
                    value="automatic"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.post-publish-settings.label') }}</v-card-title>
          <v-card-text v-if="mode === 'create'">
            <v-radio-group v-model="publishStatus" data-cy="publishStatus">
              <v-radio
                  key="onsave"
                  :label="$t('components.post-publish-settings.publish.onsave')"
                  value="onsave"
              ></v-radio>
              
              <v-radio
                  key="ondate"
                  :label="$t('components.post-publish-settings.publish.ondate')"
                  value="ondate"
              ></v-radio>
            </v-radio-group>

            <div v-if="publishStatus === 'ondate'" class="radio-group-datepicker">
              <app-date-time-picker
                  v-model="jobsData.published_at"
                  data-cy="published_at"
                  date-format="dd.MM.yyyy"
                  :id="'publishedDate'"
                  :clearable="true"
                  :label="$t('components.post-publish-settings.publish-ondate')"
              ></app-date-time-picker>

              <span class="publish-error" v-if="!validPublishDate">
                {{ $t('validation.required.publish-date') }}
              </span>
            </div>
          </v-card-text>

          <v-card-text v-else>
            <div
              v-show="initialjobsData.published_at && !isPublished(initialjobsData.published_at) && status ==='published'"
              class="pending-publish-notification"
            >
              <span><v-icon>mdi-information-outline</v-icon>
                {{ $t('components.post-publish-settings.publish-pending') }}<br>
                {{ initialjobsData.published_at | momentDate }} {{ initialjobsData.published_at | momentTime }} Uhr
              </span>
            </div>

            <v-radio-group v-model="status">
              <v-radio
                key="published"
                :label="$t('components.post-publish-settings.status.published')"
                value="published"
              ></v-radio>

              <div
                v-if="!isPublished(initialjobsData.published_at) && status ==='published'"
                class="in-radio-position"
              >
                <app-date-time-picker
                  v-model="jobsData.published_at"
                  date-format="dd.MM.yyyy"
                  time-format="HH:mm"
                  :id="'publishedDate'"
                  :clearable="true"
                  :label="$t('components.post-publish-settings.publish-ondate')"
                ></app-date-time-picker>
                <span class="publish-error" v-if="invalidPublishDate">
                  {{ $t('validation.required.publish-date') }}
                </span>
              </div>

              <v-radio
                  key="draft"
                  :label="$t('components.post-publish-settings.status.draft')"
                  value="draft"
              ></v-radio>

              <v-radio
                  key="deleted"
                  :label="$t('components.post-publish-settings.status.archived')"
                  value="deleted"
              ></v-radio>
            </v-radio-group>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
          <v-card-text>
            <permission-group-selector
              v-model="jobsData.assigned_permission_groups"
              :required="true"
              mode="dropdown"
            ></permission-group-selector>
          </v-card-text>
        </v-card>

        <v-card
          v-if="mode === 'edit'"
          elevation="0"
          class="sp-mar-top-3"
        >
          <v-card-title>{{ $t('components.post-read-analytics.label') }}</v-card-title>
          <v-card-text>
            <div :class="{ 'is-loading': !analyticsLoaded }">
              <div class="sp-flex sp-flex-dir-col full-width" v-if="analyticsLoaded">
                <div>
                  {{ $t('components.post-read-analytics.read') }}:
                  {{ analytics.read_total + '/' + analytics.total }}
                </div>

                <div
                  v-if="analytics.read_total > 0"
                  class="show-analytics-users"
                  @click="showAnalyticsUsers = !showAnalyticsUsers"
                >
                  <md-icon>supervisor_account</md-icon>
                  {{ $t('components.post-read-analytics.show-users') }}
                </div>

                <div v-if="showAnalyticsUsers">
                  <div v-for="analytics_user in analytics.users" :key="analytics_user.id" class="analytics-user">
                    {{ analytics_user }}
                  </div>
                </div>
              </div>

              <div class="sp-flex sp-flex-dir-col" v-if="analyticsError">
                <span>{{ $t('components.post-read-analytics.error-onload') }}</span>
              </div>
            </div>
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:action-buttons>
        <v-btn
          v-if="mode === 'create'"
          color="primary"
          elevation="0"
          outlined
          class="icon-left"
          :loading="processing"
          @click="saveJob(false)"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ t('draft') }}
        </v-btn>

        <v-btn
          v-if="mode === 'create'"
          color="primary"
          elevation="0"
          class="icon-left"
          :loading="processing"
          @click="saveJob(true)"
        >
          <v-icon>mdi-check</v-icon>
          <span v-if="publishStatus === 'onsave'">{{ t('publish-onsave') }}</span>
          <span v-if="publishStatus === 'ondate'">{{ t('publish-ondate') }}</span>
        </v-btn>

        <v-btn
          v-if="mode === 'edit'"
          color="primary"
          elevation="0"
          class="icon-left"
          :disabled="!hasNewBlocksContent && !wantToArchive"
          :loading="processing"
          @click="status === 'deleted' ? deleteJob() : saveJob()"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t('components.basic-edit-container.save') }}
        </v-btn>
      </template>
    </BasicCreateEditContainer>

    <v-dialog
      v-if="showPhonePreviewDialog"
      v-model="showPhonePreviewDialog"
      width="414"
      persistent
      content-class="preview-dialog custom-class-dialog"
    >
      <v-card>
        <v-card-title>
          {{ $t('components.blocks-editor.preview') }}
        </v-card-title>
        <v-card-text>
          <content-editor-preview
            :blocks="jobsData.blocks"
            content-type="jobs"
          ></content-editor-preview>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            elevation="0"
            class="icon-left"
            @click="showPhonePreviewDialog = false"
          >
            <v-icon>mdi-window-close</v-icon>
            {{ $t('components.blocks-editor.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
  
<script>
import { HTTP } from '@/auth'
import { EventBus } from '@/event-bus'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import AppDateTimePicker from '@/components/vuetify/AppDateTimePicker'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import ContentEditor from '@/components/ContentEditor'
import ContentEditorPreview from '@/components/ContentEditorPreview'
  
export default {
  name: 'JobsCreateEdit',
  components: {
    toolbar,
    BasicCreateEditContainer,
    AppDateTimePicker,
    ContentEditor,
    ContentEditorPreview,
    PermissionGroupSelector
  },
  data() {
    return {
      dataLoaded: false,
      processing: false,
      jobsData: {
        id: null,
        title: null,
        preview_text: null,
        blocks: [],
        videos: [],
        public: false,
        published_at: null,
        category: 1,
        assigned_permission_groups: [],
      },
      initialjobsData: {},
      analytics: {},
      showAnalyticsUsers: false,
      analyticsLoaded: false,
      analyticsError: false,
      pushData: {
        type: 'jobs',
        id: null,
        source: 'request'
      },
      status: '',
      categories: [],
      pushStatus: 'manual',
      publishStatus: 'onsave',
      error: null,
      validPublishDate: true,
      hasBlockContent: false,
      dataEdited: false,
      showPhonePreviewDialog: false
    }
  },
  filters: {
    momentDate: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentTime: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  created() {
    EventBus.$on('reload-item', function () {
      this.getJobsPost()
    }.bind(this))
    if (this.$route.name === 'JobsCreate') {
      this.mode = 'create'
      this.dataLoaded = true
    }
    if (this.$route.name === 'JobsEdit') {
      const id = this.$route.params.id
      if (!isNaN(id)) {
        this.mode = 'edit'
        this.jobsData.id = id
      }
    }
    this.getCategories()
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.jobsData) !== JSON.stringify(this.dataOriginal)
    },
    hasNewBlocksContent() {
      return this.jobsData.blocks && this.jobsData.blocks.length > 0
    },
    isArchived() {
      return this.mode === 'edit' && this.jobsData.deleted_at != null
    },
    wantToArchive() {
      // possibility to archive old post without new content-blocks
      return this.status == 'deleted' && this.jobsData.deleted_at === null
    }
  },
  methods: {
    t: function (key) {
      return this.$t('jobs.detail.' + key)
    },
    getToolbarTitle() {
      if (this.mode == 'create') {
        return this.t('create.title')
        } else {
        if (!this.jobsData.deleted_at) {
          return this.t('edit.title-edit')
        } else {
          return this.t('edit.title-readonly')
        }
        }
    },
    fireWatcherForDataChange() {
      this.$watch(
          (vm) => [vm.data, vm.blocks, vm.publishStatus, vm.pushStatus],
          (newVal, oldVal) => {
            if (oldVal != undefined) {
              this.dataEdited = true
            }
          },
          {
            immediate: true,
            deep: true,
          }
      );
    },
    getCategories() {
      HTTP.get('jobs/categories?sortBy[]=name').then(function (response) {
        this.categories = response.data.data
        this.error = false
        if (this.mode === 'edit') {
          this.getJobsPost()
        } else {
          this.fireWatcherForDataChange()
        }
      }.bind(this)).catch(function (error) {
        if (!error.status) {
          this.error = true
        }
      }.bind(this))
    },
    validateBlockContent() {
      if (this.jobsData.blocks.length > 0) {
        this.jobsData.blocks.forEach((block) => {
          if (block.name == 'Text' && block.content != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Video' && block.content != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Bild' && block.cropImg != '') {
            this.hasBlockContent = true
            return
          }
          if (block.name == 'Youtube' && block.content != '') {
            this.hasBlockContent = true
            return
          }
        })
      }
    },
    validatePublish(publish) {
      if (!publish) {
        return
      }
      if (this.publishStatus === 'ondate' && !this.data.published_at) {
        this.validPublishDate = false
      } else {
        this.validPublishDate = true
      }
    },
    showPhonePreview() {
      this.showPhonePreviewDialog = true
    },
    getAnalyticsData() {
      HTTP.get('analytics/jobs/' + this.jobsData.id)
        .then(function (response) {
          this.analytics = response.data
          this.analyticsLoaded = true
        }.bind(this))
        .catch(function (error) {
          this.analyticsError = true
        }.bind(this))
    },
    setPushData() {
      this.pushData.id = this.jobsData.id
    },
    isPublished(publishedAt) {
      return moment().isAfter(publishedAt)
    },
    getJobsPost() {
      HTTP.get('jobs/' + this.jobsData.id)
        .then(function (response) {
          this.jobsData = response.data

          const tempPermGroupsIds = []
          this.jobsData.assigned_permission_groups.forEach((element) => {
            tempPermGroupsIds.push(element.permission_group_id)
          })
          this.jobsData.assigned_permission_groups = tempPermGroupsIds
          
          this.initialjobsData = Object.assign({}, this.jobsData)

          if (this.isArchived) {
            this.status = 'deleted'
          } else if (this.initialjobsData.published_at || this.isPublished(this.initialjobsData.published_at)) {
            this.status = 'published'
          } else {
            this.status = 'draft'
          }

          this.setPushData()

          this.dataLoaded = true
          this.dataEdited = false

          this.getAnalyticsData()
          this.fireWatcherForDataChange()

        }.bind(this)).catch(function (error) {
          console.log(error)
          if (!error.status) {
            this.error = true
            this.dataLoaded = false
          }
        }.bind(this))
    },
    saveJob(publish) {

      this.validateBlockContent()
      this.validatePublish(publish)

      if (this.$refs.editor.validate() && this.hasBlockContent && this.validPublishDate) {

        if (this.processing) {
          return
        }

        this.processing = true

        if (publish && this.publishStatus === 'onsave') {
          this.jobsData.published_at = moment().milliseconds(0).toISOString()
        }

        if (!publish) {
          this.jobsData.published_at = null
        }

        let formData = new FormData()

        let i = 0
        this.jobsData.blocks.forEach(z => {
          z.order = i++
          if (z.name === 'Video' && z.new_content) {
            formData.append('video-' + z.order, z.video)
          }
        })

        formData.append('data', JSON.stringify(this.jobsData))
        formData.append('status', this.status)

        const config = {
          headers: {'content-type': 'multipart/form-data'}
        }

        if (this.mode === 'create') {

          HTTP.post('jobs', formData, config)
              .then(function (response) {
                this.dataEdited = false
                let tempPublishDate = this.jobsData.published_at

                if (publish) {

                  // send push if 'automatic' and publish 'onsave'
                  if (this.pushStatus === 'automatic' && this.publishStatus === 'onsave') {

                    const config = {
                      headers: {'content-type': 'application/json'}
                    }

                    var pushData = {}
                    pushData.id = response.data.id
                    pushData.type = 'jobs'
                    pushData.source = 'request'

                    HTTP.post('push', pushData, config)
                        .then(function () {
                          this.$router.push({ name: 'JobsIndex' })
                        }.bind(this))
                        .catch(function (error) {
                          console.log(error)
                          if (error.response.data.errors) {
                            this.$root.infoNotification.showMessage(error.response.data.message)
                          }
                        }.bind(this))

                    // add push cronjob if 'automatic' and publish 'ondate'
                  } else if (this.pushStatus === 'automatic' && this.publishStatus === 'ondate') {

                    const config = {
                      headers: {'content-type': 'application/json'}
                    }

                    var cronData = {}
                    cronData.id = response.data.id
                    cronData.type = 'jobs'
                    cronData.publish_date = tempPublishDate

                    HTTP.post('cron', cronData, config)
                        .then(function () {
                          this.$router.push({ name: 'JobsIndex' })
                        }.bind(this))
                        .catch(function (error) {
                          console.log(error)
                          if (error.response.data.errors) {
                            this.$root.infoNotification.showMessage(error.response.data.message)
                          }
                        }.bind(this))

                  }

                  this.$router.push({ name: 'JobsIndex' })

                  // push 'manual'
                } else {

                  this.$router.push({ name: 'JobsIndex' })

                }

              }.bind(this))
              .catch(function (error) {
                this.processing = false
                if (error.response.data.errors) {
                  this.$root.infoNotification.showMessage(error.response.data.message)
                }
              }.bind(this))

        } else {

          HTTP.post('jobs/' + this.jobsData.id, formData, config)
            .then(function (response) {

              this.data = response.data

              if (this.pushStatus === 'automatic' && this.status === 'published') {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronData = {}
                cronData.id = response.data.id
                cronData.type = this.pushData.type
                cronData.publish_date = this.jobsData.published_at

                HTTP.post('cron', cronData, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              if (this.pushStatus === 'manual' && this.isPublishedAtChanged) {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronDataUpdate = {}
                cronDataUpdate.id = response.data.id
                cronDataUpdate.type = this.pushData.type
                cronDataUpdate.publish_date = this.jobsData.published_at

                HTTP.post('updatecron', cronDataUpdate, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              if (this.status === 'draft') {

                const config = {
                  headers: {'content-type': 'application/json'}
                }

                var cronDataAbort = {}
                cronDataAbort.id = this.jobsData.id
                cronDataAbort.type = this.pushData.type

                HTTP.post('abortcron', cronDataAbort, config)
                    .then(function (response) {
                      //
                    })
                    .catch(function (error) {
                      console.log(error)
                    }.bind(this))

              }

              this.processing = false
              this.dataEdited = false
              this.$router.push({ name: 'JobsIndex' })

            }.bind(this))
            .catch(function (error) {
              if (error.response.data.errors) {
                this.message = error.response.data.message
              }
              this.errorMessage = 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbindung und versuchen Sie es nochmal.'
              this.openSnackbar()
              console.log(error.response)
              this.processing = false
            }.bind(this))
          
        }    
      }
    },
    deleteJob() {
      /*if (this.jobsData.deleted_at) {
        this.errorMessage = 'Job bereits archiviert'
        this.openSnackbar()
      } else {
        HTTP.delete('jobs/' + this.jobsData.id, this.data)
            .then(function (response) {
              this.dataEdited = false
              this.$router.push({ name: 'JobsIndex' })
            }.bind(this)).catch(function (error) {
          this.error = error
          this.openSnackbar()
        }.bind(this))
      }*/
    },
    push() {
      const config = {
        headers: {'content-type': 'application/json'}
      }

      HTTP.post('push', this.pushData, config)
          .then(function (response) {
            EventBus.$emit('reload-item')
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    pushAbort() {
      const config = {
        headers: {'content-type': 'application/json'}
      }

      var cronData = {}
      cronData.id = this.jobsData.id
      cronData.type = this.pushData.type

      HTTP.post('abortcron', cronData, config)
          .then(function (response) {
            EventBus.$emit('reload-item')
          })
          .catch(function (error) {
            console.log(error)
          }.bind(this))
    },
    close() {
      this.$router.push({ name: 'JobsIndex' })
    }
  }
}
</script>
  
<style scoped lang="scss">
.radio-group-datepicker {
  margin-top: 16px;
}

.publish-error {
  display: inline-block;
  font-size: 12px;
  color: #FF5252;
}

.phone-preview {
  float: right;
}
</style>
<template>
    <div>
        <picture-upload
            v-if="loaded"
            v-model="picture"
            :initial-picture="initialPicture"
            :control-id="'content-block-picture' + index"
            :aspectShow="true"
        ></picture-upload>
    </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'
import PictureUpload from '@/components/inputs/PictureUpload'

export default {
    name: "ContentEditorBlockImage",
    components: {
        PictureUpload
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return  {}
            }
        },
        index: {},
        contentType: {
            default: 'news'
        }
    },
    data() {
        return {
            block: this.value,
            initialPicture: null,
            picture: null,
            loaded: false
        }
    },
    watch: {
        picture(val) {
            this.block.new_content = val
        }
    },
    async created() {
        if (this.block.id) {
            await HTTP.get(this.contentType + '/image/' + this.block.id, { responseType: 'arraybuffer' })
            .then(function (response) {
                this.initialPicture = 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64')
                this.loaded = true
                this.$emit('update:block')
            }.bind(this))
        } else {
            this.loaded = true
            this.initialPicture = this.block.new_content
            this.$emit('update:block')
        }
    }
}
</script>

<template>
  <div>
    <toolbar :dataLoaded="dataLoaded">{{ title }}</toolbar>

    <v-container fluid class="edit-screen" :class="allowDelete ? customClass + ' has-delete-button' : customClass">
      <loading :error="error" :dataLoaded="dataLoaded"></loading>
      <v-form ref="form" v-on:submit.prevent>
        <v-row v-if="dataLoaded && allowDelete">
          <v-col class="content-area" cols="12" sm="12" md="8"></v-col>
          <v-col class="meta-area" cols="12" sm="12" md="4">
            <DeleteButton 
              :object-type="objectType" 
              :object-name="objectName" 
              :processing="deleting" 
              :disabled="saving"
              :deletion-hint-text="deletionHintText"
              :deletion-hint-list="deletionHintList"
              @click="doDelete"></DeleteButton>
          </v-col>
        </v-row>

        <v-row v-show="dataLoaded">
          <slot name="fluid"/>
          <v-col class="content-area" cols="12" sm="12" md="8">
            <slot name="content"/>
          </v-col>
          <v-col class="meta-area" cols="12" sm="12" md="4">
            <slot name="meta"/>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <transition name="fade">
      <div v-if="dataLoaded" class="bottom-navigation modal-nav">
        <v-container fluid>
          <v-row>
            <cancel-button-dialog
                ref="cancelButtonDialog"
                :data-edited="hasChanges"
                :disabled="saving || deleting"
                @cancel="doCancel"></cancel-button-dialog>
            <v-spacer></v-spacer>
            <slot name="secondary-action-buttons"></slot>
            <slot name="action-buttons">
              <v-btn
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  @click="doSave"
                  :disabled="!hasChanges || deleting"
                  :loading="saving"
              >
                <v-icon>mdi-check</v-icon>
                {{ $t('components.basic-edit-container.save') }}
              </v-btn>
            </slot>
          </v-row>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import toolbar from '@/components/layouts/Navigation.vue'
import DeleteButton from '@/components/inputs/DeleteButton'
import loading from '@/components/layouts/Loading'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'

export default {
  name: "BasicCreateEditContainer",
  components: {
    CancelButtonDialog,
    DeleteButton,
    toolbar,
    loading,
  },
  emits: ['saved', 'deleted', 'cancel'],
  props: {
    title: String,
    dataLoaded: {
      type: Boolean,
      required: true,
    },
    customClass: '',
    objectType: String,
    objectName: String,
    saveAction: Function,
    deleteAction: Function,
    hasChanges: {
      type: Boolean,
      required: true,
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    deletionHintText: String,
    deletionHintList: [],
    hiddenFieldValidationMessage: String,
    hiddenFieldValidationMessage2: String
  },
  data() {
    return {
      saving: false,
      deleting: false,
      error: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.cancelButtonDialog.beforeRouteLeave(to, from, next);
  },
  methods: {
    doCancel() {
      this.$emit('cancel')
    },
    async doSave() {
      
      if (this.validate()) {

        if (this.saving === true) {
          return
        }
        this.saving = true
        let root = this.$root
        try {
          await this.saveAction()

          this.$emit('saved')
        } catch (error) {
          console.log(error)
          let messageArray = []
          if (error.response.data.errors) {
            let obj = error.response.data.errors
            for (const key in error.response.data.errors) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key]
                messageArray.push(value)
              }
            }
            root.infoNotification.showMessage(messageArray.join(", "))
          } else {
            let errorData = error.response.data
            if (errorData.rule) {
              let errorMessage = this.$t('errors.rules.' + errorData.rule).replace('{0}', errorData.field)
              root.infoNotification.showMessage(errorMessage)
            } else {
              if (error.response.status !== 304) {
                root.infoNotification.showMessage('An unknown error occurred. ' + error.response.data)
              }
            }
          }

        } finally {
          this.saving = false
        }
      }
    },
    async doDelete() {
      if (this.deleting === true) {
        return
      }
      this.deleting = true
      let root = this.$root
      try {
        await this.deleteAction()
        this.$emit('deleted')
      } catch (error) {
        let messageArray = []
        if (error.response.data.errors) {
          let obj = error.response.data.errors;
          for (const key in error.response.data.errors) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const value = obj[key];
              messageArray.push(value);
            }
          }
          root.infoNotification.showMessage(messageArray.join(", "))
        } else {
          let errorData = error.response.data
          if (errorData.rule) {
            let errorMessage = this.$t('errors.rules.' + errorData.rule).replace('{0}', errorData.field)
            root.infoNotification.showMessage(errorMessage);
          } else {
            root.infoNotification.showMessage('An unknown error occurred. ' + error.response.data)
          }
        }
      } finally {
        this.deleting = false;
      }
    },
    validate() {
      // only works if there is only 1 child node inside slot
      let hiddenValidation
      let hiddenValidation2
      if (typeof this.$scopedSlots.fluid === 'function' && this.$scopedSlots.fluid()[0] && this.$scopedSlots.fluid()[0].context.$refs.hiddenValidation) {
        hiddenValidation = this.$scopedSlots.fluid()[0].context.$refs.hiddenValidation
      }
      if (typeof this.$scopedSlots.fluid === 'function' && this.$scopedSlots.fluid()[0] && this.$scopedSlots.fluid()[0].context.$refs.hiddenValidation2) {
        hiddenValidation2 = this.$scopedSlots.fluid()[0].context.$refs.hiddenValidation2
      }
      if (typeof this.$scopedSlots.content === 'function' && this.$scopedSlots.content()[0] && this.$scopedSlots.content()[0].context.$refs.hiddenValidation) {
        hiddenValidation = this.$scopedSlots.content()[0].context.$refs.hiddenValidation
      }
      if (typeof this.$scopedSlots.content === 'function' && this.$scopedSlots.content()[0] && this.$scopedSlots.content()[0].context.$refs.hiddenValidation2) {
        hiddenValidation2 = this.$scopedSlots.content()[0].context.$refs.hiddenValidation2
      }
      if (hiddenValidation && !hiddenValidation.valid) {
        this.$root.infoNotification.showMessage(this.hiddenFieldValidationMessage)
      }
      if (hiddenValidation2 && !hiddenValidation2.valid) {
        this.$root.infoNotification.showMessage(this.hiddenFieldValidationMessage2)
      }
      this.$emit('hidden-validation-done')
      return this.$refs.form.validate()
    }
  }
}
</script>

<template>
  <div>
    <p v-show="required && !selectedPicture" class="md-error">{{ $t('validation.required.thumbnail') }}</p>

    <v-radio-group row v-if="aspectShow && showCropper" v-model="localAspectMode" @change="onChange" class="mb-4" >
      <label class="mr-4">{{ $t('galleries.gallery.detail.picture-upload.aspect-ratio') }}</label>
      <v-radio :label="$t('galleries.gallery.detail.picture-upload.portrait')"  value="portrait"></v-radio>
      <v-radio :label="$t('galleries.gallery.detail.picture-upload.landscape')" value="landscape"></v-radio>
      <v-radio :label="$t('galleries.gallery.detail.picture-upload.quadratic')" value="quadratic"></v-radio>
    </v-radio-group>

    <input
      class="file-input"
      :id="'fileUpload-' + uniqueControlId"
      :ref="'input-' + uniqueControlId"
      type="file"
      :name="'image-' + uniqueControlId"
      accept="image/*"
      @change="onImageSelected"
    />

    <section v-show="showCropper" class="cropper-area" >
      <div class="img-cropper">
        <vue-cropper
          v-if="refreshingRatio"
          :ref="'cropper-' + uniqueControlId"
          :src="contentFromFile"
          :preview="'.preview-' + uniqueControlId"
          :aspectRatio="aspectRatio"
          :zoomable="false"
          :checkCrossOrigin="false"
          :responsive="false"
          :autoCropArea="1"
        />
      </div>
    </section>

    <section v-show="showCropper || imageChanged" class="preview-area">
      <v-btn v-show="deletion && imageChanged" @click="deletePicture()" class="ma-2 deleteButton" outlined style="background-color: white">
        <md-icon class="sp-text-blue">delete</md-icon>
      </v-btn>
      <div :class="previewClassName" class="preview">
        <!-- Cropper component will replaces this content with a crop image preview. -->
      </div>
    </section>

    <section v-show="selectedPicture && selectedPicture.length > 0 && !showCropper && !contentFromFile" class="preview-area">
      <v-img :src="selectedPicture" style="width: 100%; text-align: right" class="mb-4">
        <v-btn v-show="deletion" @click="deletion && deletePicture()" class="ma-2" outlined style="background-color: white">
          <md-icon class="sp-text-blue">delete</md-icon>
        </v-btn>
      </v-img>
    </section>

    <div v-show="!showCropper" class="sp-float-right">
      <v-btn
        elevation="0"
        color="primary"
        outlined
        class="icon-left"
        @click="showFileChooser()"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('components.picture-upload.upload') }}
      </v-btn>
    </div>

    <div v-show="showCropper">
      <div class="sp-float-left">
        <v-btn
          elevation="0"
          color="primary"
          outlined
          class="icon-left"
          @click="resetCropper()"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('components.picture-upload.cancel') }}
        </v-btn>
      </div>

      <div class="sp-float-right">
        <v-btn
          elevation="0"
          color="primary"
          class="icon-left"
          @click="cropImage()"
        >
          <v-icon>mdi-crop</v-icon>
          {{ $t('components.picture-upload.crop') }}
        </v-btn>

      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'

export default {
  name: "PictureUpload",
  components: {
    VueCropper,
  },
  props: {
    value: {
      type: [Object, String],
      default: () => ''
    },
    aspectShow: {
      type: Boolean,
      default: false,
    },
    aspectMode: {
      type: String,
      default: "landscape",
    },
    required: {
      type: Boolean,
      default: false,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    deletion: {
      type: Boolean,
      default: false,
    },
    initialPicture: {
      type: String,
      default: null
    },
    controlId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      imageChanged: false,
      missingThumbnail: false,
      showCropper: false,
      contentFromFile: null,
      croppedContent: null,
      uniqueControlId: this.generateUniqueId(),
      refreshingRatio: true,
      localAspectMode: this.aspectMode,
      selectedPicture: null
    }
  },
  created() {
    this.uniqueControlId = this.generateUniqueId();
    if (this.initialPicture) {
      this.selectedPicture = {}
      let cacheBuster = '';
      if (this.initialPicture.includes("base64")) {
        this.selectedPicture = this.initialPicture
      } else {
        cacheBuster = new Date().getTime()
        this.selectedPicture =`${this.initialPicture}?${cacheBuster}`
      }
    }
  },
  watch: {
    selectedPicture: function(val) {
      this.$emit('input', val);
    }
  },
  computed: {
    aspectRatio() {
      let aspectratio;
      if (this.localAspectMode === "landscape") {
        aspectratio = 16 / 9
      }
      if (this.localAspectMode === "quadratic") {
        aspectratio = 1
      }
      if (this.localAspectMode === "portrait") {
        aspectratio = 9 / 16
      }
      return aspectratio;
    },
    previewClassName() {
      let result = ''
      result += 'preview-' + this.uniqueControlId
      result += " " + this.localAspectMode
      return result
    }
  },
  methods: {
    onChange() {
      this.refreshingRatio = false
      this.$nextTick(() => {
        this.refreshingRatio = true
      })
    },
    generateUniqueId() {
      return Date.now() + '-' + Math.random().toString(36).substring(2, 9)
    },
    showFileChooser() {
      this.$refs['input-' + this.uniqueControlId].click()
    },
    onImageSelected(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Bitte ein Bild auswählen')
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.contentFromFile = event.target.result
          this.setCropperContent()
          this.showCropper = true
          this.$refs['input-' + this.uniqueControlId].value = null
        }
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    setCropperContent() {
      this.$refs['cropper-' + this.uniqueControlId].replace(this.contentFromFile)
    },
    resetCropper() {
      this.setCropperContent()
      this.showCropper = false
    },
    cropImage() {
      this.showCropper = false
      this.croppedContent = this.$refs['cropper-' + this.uniqueControlId].getCroppedCanvas().toDataURL()
      this.missingThumbnail = false
      this.imageChanged = true
      this.selectedPicture = this.croppedContent
    },
    deletePicture() {
      this.selectedPicture = null
      this.imageChanged = false
    }
  }
}
</script>

<style scoped lang="scss">
.file-input {
  display: none;
}

.cropper-area {
  margin-bottom: 20px;
}

.preview-area {
  width: 50%;
  max-width: 400px;
  margin-bottom: 20px;
}

.preview {
  width: 100% !important;
  height: 0;
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);

  &.quadratic {
    padding-bottom: 100%;
  }&.landscape {
       padding-bottom: 56.25%;
  }&.portrait {
    padding-bottom: 177%;
  }
}

.block-preview {
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);
}

.preview img {
  width: 100% !important;
  height: 100% !important;
}

.cropped-image img {
  max-width: 100%;
}

.deleteButton {
  float: right;
  bottom: -55px;
  right: 10px;
}

</style>

<template>
    <div>

       <BasicCreateEditContainer
            :title="$t('menu.edit.header_title', { moduleName: data['title_de'] })"
            ref="editor"
            :data-loaded="dataLoaded"
            @cancel="cancel"
            :object-name="data.title_de"
            :saveAction="saveItem"
            @saved="onSaved"
            :allow-delete="mode == 'edit'"
            :delete-action="deleteItem"
            @deleted="onDeleted"
            :has-changes="hasChanges"
            :hidden-field-validation-message="$t('menu.validation.hidden-field-validation-icon-module')"
            :hidden-field-validation-message2="$t('menu.validation.hidden-field-validation-blocks')"
            @hidden-validation-done="handleHiddenValidation"
        >
            <template v-slot:content>

                <v-card elevation="0">

                    <v-card-title>{{ t('details-title') }}</v-card-title>

                    <v-card-text>

                        <div v-show="data.type === 'module'">
                            <loading v-if="!showModules"></loading>
                            <div v-else>
                                <div class="module-wrapper">
                                    <v-btn
                                        v-for="(item, index) in modules" :key="index" v-if="item.active && activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        disabled
                                    >
                                        <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                        {{ item.title_de }}
                                        <v-icon>
                                            mdi-check-bold
                                        </v-icon>
                                    </v-btn>
                                </div>

                                <div class="module-wrapper" >
                                    <v-btn
                                        v-for="(item, index) in modules" :key="index" v-if="item.active && !activeMenuItems.includes(item.id)"
                                        class="chooseIconButton"
                                        elevation="0"
                                        :disabled="mode === 'edit'"
                                        @click="setActiveButton(item)"
                                    >
                                        <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                        {{ item.title_de }}
                                        <v-icon v-if="data.module_id == item.id">
                                            mdi-chevron-down-circle-outline
                                        </v-icon>
                                        <v-icon v-else>
                                            mdi-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </div>

                                <div class="clear-both">
                                    <div class="ml-2">{{ t('other-modules') }}</div>
                                    <div class="module-wrapper">
                                        <v-btn
                                            v-for="(item, index) in modules" :key="index" v-if="!item.active"
                                            class="chooseIconButton"
                                            elevation="0"
                                            disabled
                                        >
                                            <v-icon class="v-icon">{{ item.icon }}</v-icon>
                                            {{ item.title_de }}
                                        </v-btn>
                                    </div>
                                    <div class="mt-4 ml-2 clear-both" v-show="data.type == 'module'">
                                        <a href="mailto:michael.fretz@cubera.ch">{{ t('extend-solution') }}</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style="clear:both;;">

                            <choose-icon class="ml-2" v-model="data.icon" :compact="true"></choose-icon>
                    
                            <v-text-field
                                class="mt-8"
                                :label="t('title') + ' EN*'"
                                v-model="data.title_en"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_en || $t('validation.required.default'),
                                    () => (data.title_en && data.title_en.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' DE*'"
                                v-model="data.title_de"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_de || $t('validation.required.default'),
                                    () => (data.title_de && data.title_de.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' IT*'"
                                v-model="data.title_it"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_it || $t('validation.required.default'),
                                    () => (data.title_it && data.title_it.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' FR*'"
                                v-model="data.title_fr"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_fr || $t('validation.required.default'),
                                    () => (data.title_fr && data.title_fr.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' ES*'"
                                v-model="data.title_es"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_es || $t('validation.required.default'),
                                    () => (data.title_es && data.title_es.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' PT*'"
                                v-model="data.title_pt"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_pt || $t('validation.required.default'),
                                    () => (data.title_pt && data.title_pt.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' TR*'"
                                v-model="data.title_tr"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_tr || $t('validation.required.default'),
                                    () => (data.title_tr && data.title_tr.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' UK*'"
                                v-model="data.title_uk"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_uk || $t('validation.required.default'),
                                    () => (data.title_uk && data.title_uk.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>

                            <v-text-field
                                :label="t('title') + ' RU*'"
                                v-model="data.title_ru"
                                :counter="maxTitleLength"
                                filled
                                :rules="[
                                    () => !!data.title_ru || $t('validation.required.default'),
                                    () => (data.title_ru && data.title_ru.length <= maxTitleLength) || $t('menu.validation.length-message', { maxLength: maxTitleLength })
                                ]"
                            ></v-text-field>
                            
                            <v-text-field
                                v-if="data.type == 'webview' || data.type == 'external_browser'"
                                :label="t('url')"
                                v-model="data.url"
                                filled
                                hide-details="auto"
                                :rules="[
                                    () => !!data.url || $t('validation.required.default'),
                                ]"
                            ></v-text-field>
                    
                            <v-text-field
                                ref="hiddenValidation"
                                class="hidden"
                                v-model="noIconOrModuleValue"
                                :rules="[
                                    () => !!noIconOrModuleValue || $t('menu.validation.hidden-field-validation-icon-module')
                                ]"
                            ></v-text-field>

                            <v-text-field
                                v-if="data.type === 'content_block'"
                                ref="hiddenValidation2"
                                class="hidden"
                                v-model="noContentBlocksValue"
                                :rules="[
                                    () => !!noContentBlocksValue || $t('menu.validation.hidden-field-validation-blocks')
                                ]"
                            ></v-text-field>

                            <content-editor
                                v-show="data.type == 'content_block'"
                                content-type="menu"
                                :blocks-validation-enabled="false"
                                :show-block-validation="hiddenValidationDone"
                                v-model="data.blocks"
                                @update:blocks="hasValidBlockContent = $event"
                            />
                                
                        </div>
                    </v-card-text>      

                </v-card>

            </template>

            <template v-slot:meta>
                <v-card elevation="0">
                    <v-card-title>{{ $t('components.permissiongroup-select.label') + '*' }}</v-card-title>
                    <v-card-text>
                        <permission-group-selector
                            v-model="data.assigned_permission_groups"
                            :required="true"
                            mode="dropdown"
                        />
                    </v-card-text>
                </v-card>
            </template>

        </BasicCreateEditContainer>
    </div>
</template>

<script>
import auth from '@/auth/index'
import { HTTP } from '@/auth'
import BasicCreateEditContainer from '@/components/inputs/BasicCreateEditContainer'
import ChooseIcon from '@/components/dialogs/ChooseIcon'
import store from 'store'
import ContentEditor from '@/components/ContentEditor'
import PermissionGroupSelector from '@/components/PermissionGroupSelector'
import loading from '@/components/layouts/Loading'

export default {
    name: 'MenuCreateEdit',
    components: {
        BasicCreateEditContainer,
        ChooseIcon,
        ContentEditor,
        PermissionGroupSelector,
        loading
    },
    data() {
        return {
            itemId: null,
            dataLoaded: false,
            data: {
                title_en: '',
                title_de: '',
                title_it: '',
                title_fr: '',
                title_es: '',
                title_pt: '',
                title_tr: '',
                title_uk: '',
                title_ru: '',
                url: '',
                icon: '',
                type: '',
                assigned_permission_groups: [],
                module_id: 0,
                blocks: []
            },
            type: '',
            mode: null,
            dataOriginal: null,
            showModules: false,
            modules: store.get('modules'),
            activeMenuItems: [],
            maxTitleLength: 40,
            hasValidBlockContent: false,
            hiddenValidationDone: false
        }
    },
    mounted() {
        this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    },
    created() {
        this.itemId = this.$route.params.id
        this.data.type = this.$route.params.type
        if (this.itemId) {
            this.getMenu()
            this.mode = 'edit'
        } else {
            this.mode = 'create'
            this.getActiveMenuItems()
            this.dataOriginal = JSON.parse(JSON.stringify(this.data))
            this.dataLoaded = true
        }
    },
    computed: {
        hasChanges() {
            return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal)
        },
        noIconOrModuleValue() {
            if (this.data.type === 'module') {
                return this.data.icon !== '' && this.data.module_id > 0 ? 'hasIconAndModule' : null
            } else {
                return this.data.icon !== '' ? 'hasIcon' : null
            }       
        },
        hasBlocksContent() {
            return this.data.blocks && this.data.blocks.length > 0
        },
        noContentBlocksValue() {
            return this.hasBlocksContent && this.hasValidBlockContent ? 'hasBlocks' : null
        }
    },
    methods: {
        t: function (key) {
            return this.$t('menu.edit.' + key)
        },
        setActiveButton(item) {
            if (this.data.module_id != item.id) {
                this.data.module_id = item.id;
                this.data.icon = item.icon;
                this.data.title_en = item.title_en;
                this.data.title_de = item.title_de;
                this.data.title_it = item.title_it;
                this.data.title_fr = item.title_fr;
                this.data.title_es = item.title_es;
                this.data.title_pt = item.title_pt;
                this.data.title_tr = item.title_tr;
                this.data.title_uk = item.title_uk;
                this.data.title_ru = item.title_ru;
            }
        },
        getActiveMenuItems() {
            HTTP.get('activeMenuItems/' + this.data.module_id).then(function (response) {
                this.activeMenuItems = response.data
                this.showModules = true;
            }.bind(this)).catch(function (error) {
                this.error = error
            }.bind(this))
        },
        async getMenu() {
            await HTTP.get('menus/' + this.itemId).then((response) => {

                let refactor = response.data;

                const tempPermGroupsIds = []
                response.data.assigned_permission_groups.forEach((element) => {
                    tempPermGroupsIds.push(element.permission_group_id)
                })

                refactor.assigned_permission_groups = tempPermGroupsIds
            
                this.dataLoaded = true
                this.data = refactor

                this.getActiveMenuItems()
            })
        },
        saveItem() {
            let formData = new FormData()

            let i = 0
            this.data.blocks.forEach(z => {
                z.order = i++
                if (z.name === 'Video' && z.new_content) {
                    formData.append('video-' + z.order, z.video)
                }
            })

            formData.append('data', JSON.stringify(this.data))

            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }

            if (this.mode === 'edit') {
                return HTTP.post('/menus/' + this.data.id, formData, config)
            } else {
                return HTTP.post('/menus', formData, config)
            }          
        },
        onSaved() {
            auth.loadConfig()
            this.close()
        },
        deleteItem() {
            return HTTP.delete('menus/' + this.itemId)
        },
        onDeleted() {
            auth.loadConfig()
            this.close()
        },
        close() {
            this.cancel()
        },
        cancel() {
            this.$router.push({ name: 'MenuIndex'})
        },
        handleHiddenValidation() {
            this.hiddenValidationDone = true
        }
    }
}
</script>

<style scoped lang="scss">
.module-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 16px 0;
}

.chooseIconButton {
    margin: 5px;
    float: left;

    .v-icon {
        margin: 5px;
    }
}

.clear-both {
    clear: both;
}
</style>

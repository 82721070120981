<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                outlined
                class="icon-left blocks-button"
                v-bind="attrs"
                v-on="on"
            >
                <v-icon>mdi-plus</v-icon>
                {{ $t('components.blocks-editor.add-content') }}
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="(blockitem, index) in blocksList"
                :key="index"
                class="blocks-list-item"
                @click="addBlock(blockitem.name)"
            >
                <v-list-item-title>
                    <v-icon>{{ blockitem.icon }}</v-icon>
                    {{ blockitem.name }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ContentEditorBlockAdd",
    emits: ['add-block'],
    computed: {
        blocksList() {
            return [
                {
                    name: this.$t('components.blocks-editor.blocks.text.group-title'),
                    icon: 'mdi-text-box-plus'
                },
                {
                    name: this.$t('components.blocks-editor.blocks.image.group-title'),
                    icon: 'mdi-file-image-plus-outline'
                },
                {
                    name: this.$t('components.blocks-editor.blocks.video.group-title'),
                    icon: 'mdi-video-plus-outline'
                },
                {
                    name: this.$t('components.blocks-editor.blocks.youtube.group-title'),
                    icon: 'mdi-youtube'
                }
            ]
        },
    },
    methods: {
        addBlock(block) {
            this.$emit('add-block', block)
        }
    }

}
</script>

<style scoped lang="scss">
.blocks-list-item {
    cursor: pointer;
    padding: 0px 10px;

    i {
        margin-right: 8px;
    }

    &:hover {
        background: #F5F5F5;
    }
}
</style>

<template>
    <div>
        <content-editor-block-add @add-block="addBlock"/>

        <div v-show="!hasBlockContent && blocksValidationEnabled" class="has-no-content">
            <v-icon>mdi-info</v-icon>
            {{ $t('validation.required.blocks-editor') }}
        </div>

        <draggable @change="onDragChange" element="div" v-model="blocks" :sort="true" class="blocks-wrapper">
          <v-card
            elevation="0"
            class="block-element"
            v-for="(block, index) in blocks"
            :key="index"
          >
            <v-card-title>
              <v-icon>mdi-drag</v-icon>
              <v-icon v-if="showBlockValidation && !block.valid">mdi-alert</v-icon>
              {{ block.name }}
              <span class="move-block">
                <span
                  v-if="index > 0"
                  @click="moveUp(index)"
                  class="moveup-block"
                  :title="$t('components.blocks-editor.moveup')"
                  data-cy="dataCy"
                >
                  <v-icon>mdi-arrow-up-thin</v-icon>
                </span>

                <span
                  v-if="index < blocks.length - 1"
                  @click="moveDown(index)"
                  class="movedown-block"
                  :title="$t('components.blocks-editor.movedown')"
                  data-cy="dataCy"
                >
                  <v-icon>mdi-arrow-down-thin</v-icon>
                </span>
              </span>
              
              <span
                @click="removeBlock(index)"
                class="remove-block"
                :title="$t('components.blocks-editor.remove')"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </span>
            </v-card-title>
            <v-card-text>

              <content-editor-block-text
                v-if="block.name === 'Text'"
                :index="index"
                v-model="blocks[index]"
              />

              <content-editor-block-image
                v-if="block.name === 'Bild'"
                :content-type="contentType"
                :index="index"
                @update:block="onUpdateBlock"
                v-model="blocks[index]"
              />

              <content-editor-block-video
                v-if="block.name === 'Video'"
                :content-type="contentType"
                :index="index"
                :key="refreshKey"
                @update:block="onUpdateBlock"
                v-model="blocks[index]"
              />

              <content-editor-block-youtube
                v-if="block.name === 'Youtube'"
                :key="refreshKey"
                v-model="blocks[index]"
              />

            </v-card-text>
          </v-card>
        </draggable>

    </div>
</template>

<script>
import draggable from 'vuedraggable'
import ContentEditorBlockAdd from '@/components/ContentEditorBlockAdd'
import ContentEditorBlockText from '@/components/ContentEditorBlockText'
import ContentEditorBlockImage from '@/components/ContentEditorBlockImage'
import ContentEditorBlockVideo from '@/components/ContentEditorBlockVideo'
import ContentEditorBlockYoutube from '@/components/ContentEditorBlockYoutube'

export default {
  name: "ContentEditor",
  components: {
    draggable,
    ContentEditorBlockYoutube,
    ContentEditorBlockVideo,
    ContentEditorBlockImage,
    ContentEditorBlockText,
    ContentEditorBlockAdd
      
  },
  model: {
    prop: 'model',
    event: 'input'
  },
  props: {
    dataCy: {
      default: ''
    },
    model: {
      type: Array,
      default: () => [null]
    },
    contentType: {
      default: 'news'
    },
    blocksValidationEnabled: {
      type: Boolean,
      default: true
    },
    showBlockValidation: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      blocks: this.model,
      refreshKey: 0
    }
  },
  watch : {
    model: {
      handler() {
        this.blocks = this.model
        this.$emit('update:blocks', this.hasBlockContent)
      }, deep: true
    }
  },
  computed: {
    hasBlockContent() {
      this.refreshKey
      let validBlocksLength = this.blocks.filter((block) => {
        let blockValid = (block.name === 'Text' && block.content !== '' && block.content !== '<p></p>')
          || (block.name === 'Video' && block.new_content !== '')
          || (block.name === 'Bild' && block.new_content && block.new_content !== '')
          || (block.name === 'Youtube' && block.content !== '')
        block.valid = blockValid
        return blockValid
      }).length
      return validBlocksLength > 0 && validBlocksLength === this.blocks.length
    }
  },
  methods: {
    onDragChange() {
      this.$emit('input', this.blocks);
    },
    onUpdateBlock() {
      this.$nextTick(function () {
        this.refreshKey++
        this.$emit('update:blocks', this.hasBlockContent)
      }.bind(this))
    },
    addBlock(name) {
      this.blocks.push({
          name: name,
          content: '',
          order: null,
          new_content: null,
          valid: true
      })
    },
    validateBlockContent() {
      return this.hasBlockContent
    },
    removeBlock(index) {
      this.blocks.splice(index, 1)
      this.refreshKey++
      this.$emit('input', this.blocks)
    },
    moveUp(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index - 1, 0, temp)
      this.refreshKey++
      this.$emit('input', this.blocks)
    },
    moveDown(index) {
      let temp = this.blocks[index]
      this.removeBlock(index)
      this.blocks.splice(index + 1, 0, temp)
      this.refreshKey++
      this.$emit('input', this.blocks)
    },
    onVideoSelected(video, index) {
      this.blocks[index].new_content = video.name
      this.blocks[index].video = video
    }
  }
}
</script>

<style scoped lang="scss">
.has-no-content {
  color: #FF5252 !important;
  height: 20px;
  font-size: 12px;
  margin-top: 12px;
}

.block-element {
  margin-top: 16px;

  .v-card__title {
    cursor: grab;
    padding-right: 72px;
    background: #F5F5F5;
    border-bottom: 1px solid #E0E0E0;
  }
}

.remove-block {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 56px;
  height: 56px;
  padding: 16px;
  border-left: 1px solid #E0E0E0;
  transition: background 0.3s;

  &:hover {
    background: #FFFFFF;
  }
}

.move-block {
  position: absolute;
  right: 56px;
  top: 0;
  width: 112px;
  height: 56px;
  text-align: right;

  & > span {
    cursor: pointer;
    display: inline-block;
    width: 56px;
    height: 56px;
    padding: 16px;
    border-left: 1px solid #E0E0E0;
    transition: background 0.3s;

    &:hover {
      background: #FFFFFF;
    }
  }
}

.video-wrapper {
  height: 240px;
  margin-bottom: 16px;

  video {
    height: 240px;
  }
}

.video-size-hint,
.video-size-error {
  line-height: 36px;
}

.video-size-error {
  color: var(--v-error-base);

  i {
    color: var(--v-error-base);
  }
}
</style>

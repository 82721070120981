<template>
    <v-text-field
            label="Youtube-ID*"
            counter="11"
            v-model="content"
            filled
            :rules="[
                () => !!content || $t('validation.required.youtube-id'),
                () => (content && content.length === 11) || $t('validation.length.youtube-id')
            ]"
            @keyup="(event) => content = getYoutubeId(event.target.value)"
    ></v-text-field>
</template>

<script>
export default {
    name: "ContentEditorBlockYoutube",
    props: {
        value: {}
    },
    data() {
        return {
            block: this.value,
            content: this.value.content,
            editorFocused: false
        }
    },
    watch: {
        content(val) {
            this.block.content = val
        },
    },
    methods: {
        getYoutubeId(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
            const match = url.match(regExp)
            if (url.length > 11 && match) {
                return match && match[2].length === 11 ? match[2] : undefined
            }
            return url
        },
    }
}
</script>
